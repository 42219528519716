import { useStore } from 'vuex';

export default defineNuxtRouteMiddleware(async (context) => {
  const store = useStore();
  const secondaryOrgId = store.getters.getSecondaryOrgId;

  const themeId = useRoute().params.id;
  const authToken = useRoute().params.token;

  let configResponse;

  try {
    configResponse = await useNuxtApp().$axios.get(`api/v4/o/${secondaryOrgId}/cms/config`);
    if (configResponse.data.theme_preview_token !== authToken) {
      return abortNavigation({
        statusCode: 404,
        message: 'Error fetching config',
      });
    }
  } catch (error) {
    console.error('Error fetching config', {
      secondaryOrgId,
      themeId,
      error,
    });
    return abortNavigation({
      statusCode: 404,
      message: 'Error fetching config',
    });
  }

  if (configResponse.data.theme_preview_token !== authToken) {
    const error = new Error('Theme Preview Token Mismatch');
    error.response = {
      status: 404,
      config: {
        params: {
          themeId,
          authToken,
          configResponse: configResponse?.data,
        },
      },
    };

    console.error(error);

    return abortNavigation({
      statusCode: 404,
      message: 'Invalid Theme Preview Token',
    });
  }

  let themesResponse;

  try {
    themesResponse = await useNuxtApp().$axios.get(`api/v4/o/${secondaryOrgId}/cms/themes/${themeId}`);
  } catch (error) {
    console.error('Error fetching theme', {
      secondaryOrgId,
      themeId,
      error,
    });
    return abortNavigation({
      statusCode: 404,
      message: 'Error fetching theme',
    });
  }

  if (themesResponse.data.previewable) {
    store.commit('setTheme', themesResponse.data.content);
    store.commit('setFont', themesResponse.data.font);
    store.commit('setAccentStyling', themesResponse.data.accent_styling);
    store.commit('setAccentType', themesResponse.data.accent_type);
    store.commit('setUpPreviewGallery', themesResponse.data.content.gallery.options);
    store.commit('setUpPreviewFooter', themesResponse.data.content.footer.options);
    return;
  } else {
    return abortNavigation({
      statusCode: 404,
      message: 'Theme Preview Not Available',
    });
  }
});
